import React from "react";
import Spinner from "react-bootstrap/Spinner";
import styled from "styled-components";

export const Loader = () => {
  return (
    <Root>
      <Spinner animation="border" variant="primary" />
    </Root>
  );
};

const Root = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9999;
`;
