import React from "react";
import styled from "styled-components";
import {
  Blackdescription,
  PurpleSubHeading,
  Textwithborder,
} from "../../Global/GlobalText";
import { PurpleSecondBackground } from "../../Global/GlobalButton";
import { FaArrowRightLong } from "react-icons/fa6";
import digitalmarketing from "../../assets copy/Images/digitalmarketing.png";

export const Section2 = () => {
  return (
    <Root>
      <div className="main_div">
        <div className="sub_main_div_one">
          <div className="text_border">
            <Textwithborder> Explore</Textwithborder>
            <Textwithborder>our</Textwithborder>
            <Textwithborder>Service</Textwithborder>
          </div>

          <Blackdescription>
            Explore our service and discover solutions designed to meet your
            unique needs. With a focus on quality and innovation, we turn your
            vision into reality.
          </Blackdescription>
          <div>
            <PurpleSecondBackground className="connect_btn">
              Explore more <FaArrowRightLong />
            </PurpleSecondBackground>
          </div>
        </div>

        <div className="sub_main_div_two">
          <div className="sub_tech_div">
            <div className="logo_content">
              <img src={digitalmarketing} alt="Digital Marketing" />
              <PurpleSubHeading>Digital Marketing</PurpleSubHeading>
            </div>
            <Blackdescription>
              Phanom Professionals focuses on delivering Digital Marketing
              services that make your business a market leader.
            </Blackdescription>
            {/* <div className="tools_div">
              <Greydescription>Tools: </Greydescription>
              <img src={reactjss} alt="React-Js" />
              <img src={greenserver} alt="DataBase" />
              <img src={blueserver} alt="DataBase" />
              <img src={java} alt="Javascript" />
            </div> */}
          </div>

          <div className="sub_tech_div">
            <div className="logo_content">
              <img src={digitalmarketing} alt="Digital Marketing" />
              <PurpleSubHeading>Design & Development</PurpleSubHeading>
            </div>
            <Blackdescription>
              Phanom Professionals offers world-class Design & development
              services that changes the way businesses perform on the internet
            </Blackdescription>
          </div>

          <div className="sub_tech_div">
            <div className="logo_content">
              <img src={digitalmarketing} alt="Digital Marketing" />
              <PurpleSubHeading>Animation & Graphics</PurpleSubHeading>
            </div>
            <Blackdescription>
              We are a 360 marketing agency, empowering brands with creative
              expertise, art skills, and cutting-edge graphics and animation.
            </Blackdescription>
          </div>

          <div className="sub_tech_div">
            <div className="logo_content">
              <img src={digitalmarketing} alt="Digital Marketing" />
              <PurpleSubHeading>E-Commerce Solution</PurpleSubHeading>
            </div>
            <Blackdescription>
              Acquire High Business Growth with Phanom Professional to convince
              your shoppers into reimbursing customers.
            </Blackdescription>
          </div>
        </div>
      </div>
    </Root>
  );
};

const Root = styled.section`
  padding: 70px 100px;
  .main_div {
    display: flex;
    gap: 20px;
    .sub_main_div_one {
      width: 30%;
      display: flex;
      gap: 20px;
      flex-direction: column;

      .text_border {
        display: flex;
        gap: 5px;
        flex-direction: column;
      }

      .connect_btn {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }

    .sub_main_div_two {
      width: 70%;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .sub_tech_div {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        box-shadow: 1.68px 8.39px 20.13px 0px #0000002e;

        width: 48%;
        border-radius: 10px;
        .logo_content {
          display: flex;
          gap: 20px;
          align-items: center;
        }
        .tools_div {
          display: flex;
          gap: 10px;
          align-items: center;
        }
      }
    }
  }

  @media (max-width: 567px) {
    padding: 30px 20px;

    .main_div {
      flex-direction: column;
      .sub_main_div_one {
        width: 100%;
        align-items: center;
        text-align: center;

        .text_border {
          flex-direction: row;
          justify-content: center;
          flex-wrap: wrap;
        }
      }

      .sub_main_div_two {
        width: 100%;
        .sub_tech_div {
          width: 100%;
        }
      }
    }
  }

  @media (min-width: 567px) and (max-width: 1024px) {
    padding: 30px 20px;

    .main_div {
      flex-direction: column;
      .sub_main_div_one {
        width: 100%;
        align-items: center;
        text-align: center;
        .text_border {
          flex-direction: row;
          justify-content: center;
          flex-wrap: wrap;
        }
      }

      .sub_main_div_two {
        width: 100%;
        justify-content: center;
      }
    }
  }
`;
