import React  from "react";
import styled from "styled-components";
import {
  GradientSecondHeading,
  GradientSubHeading,
  Greydescription,
  Heading,
  Subdescription,
} from "../../../Global/GlobalText";

import {
  PurpleBackground,
} from "../../../Global/GlobalButton";

import { FaArrowRightLong } from "react-icons/fa6";

import "react-datepicker/dist/react-datepicker.css";

import { AppoinmentForm } from "../../AppointmentForm/AppoinmentForm";

export const Section10 = () => {

  return (
    <Root>
      <div className="main_div" id="form">
        <div className="sub_div_one">
          <Greydescription>
            Explore a rewarding career in the Phenom Professional Industry. Fill
            out the form to get started.
          </Greydescription>

          <span>
            <Heading>With the Help of Our Strong </Heading>

            <GradientSecondHeading>Ppc</GradientSecondHeading>
            <br />

            <GradientSecondHeading>Campaigns,</GradientSecondHeading>

            <Heading>You Can Unlock Your Business Like Never Before.</Heading>
          </span>

          <Greydescription>
            It is difficult to gain attention on YouTube? Our experienced
            marketing consultants guarantee that your campaigns are targeted
            toward the correct people, to improve your video views and
            engagement further. You could be a small business or an enterprise,
            we cater to everyone by providing specialized YouTube marketing
            services. From strategic keywords targeting to video content
            creation, we make sure your videos rank on first page.
          </Greydescription>

          <div className="pointer_div">
            <Greydescription>What You’ll Get:</Greydescription>
            <ul>
              <li>Ad text, titles and descriptions optimized for ranking.</li>

              <li>
                Ad campaigns that guarantee higher returns than investments.
              </li>

              <li>Visibility, subscriber and audience retention growth.</li>

              <li>Want To Witness Your YouTube Channel Growth Over Night?</li>
            </ul>
          </div>

          <Greydescription>
            Transform your YouTube account to a channel that can be used as a
            business tool and asset today.
          </Greydescription>

          <div>
            <PurpleBackground className="connect_btn">
              Connect with us <FaArrowRightLong />
            </PurpleBackground>
          </div>
        </div>

        <div className="sub_div_two">
          <span>
            <GradientSubHeading> Book Your Appointment</GradientSubHeading>
            <Subdescription>
              Where innovation meets unstoppable growth
            </Subdescription>
          </span>


             <AppoinmentForm/>


        </div>
      </div>
    </Root>
  );
};

const Root = styled.section`
  padding: 30px 100px;
  .main_div {
    gap: 100px;
    display: flex;
    .sub_div_one {
      gap: 20px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      flex: 1;

      .pointer_div {
        display: flex;
        gap: 10px;
        flex-direction: column;
      }

      .connect_btn {
        display: flex;

        align-items: center;
        gap: 10px;
      }
    }

    .sub_div_two {
      flex: 1;
      padding: 0 30px 30px;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      span {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .label_content {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .schedule_div {
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
              0 1px 2px rgba(0, 0, 0, 0.24);

            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            border-radius: 10px;
            z-index: 111;
            position: absolute;
            background: #ffff;
            margin-top: 76px;
            min-width: 40%;
            .css-13cymwt-control {
              border: 1px solid #66666659;
              border-radius: 12px;
              padding: 5px;
              font-family: "Poppins", sans-serif;
              font-size: 13px;
              font-weight: 400;
              line-height: 24px;
              text-align: left;
              color: #333333;
            }

            .react-datepicker-wrapper {
              border: 1px solid #66666659;
              border-radius: 12px;
              padding: 0;
              font-family: "Poppins", sans-serif;
              font-size: 13px;
              font-weight: 400;
              line-height: 24px;
              text-align: left;
              color: #333333;
              overflow: hidden;
              input {
                width: 100%;
                padding: 12px;
                color: #777777;
                font-size: 13px;
                font-weight: 400;
                line-height: 24px;
              }
            }

            .time_date_btn {
              display: flex;
              justify-content: center;
              gap: 50px;
            }
          }
        }
        .submit_btn {
          display: flex;
          justify-content: center;
          button {
            padding: 15px 60px;
          }
        }
      }
    }
  }

  @media (max-width: 567px) {
    padding: 10px 20px;

    .main_div {
      flex-wrap: wrap;
      gap: 20px;
      .sub_div_one {
        text-align: center;
        gap: 20px;
        align-items: center;
        .purpl_cont {
          color: #6c5fd4;
          font-size: 16px;
        }
        span {
          font-size: 22px;
          br {
            display: none;
          }
        }
      }

      .btn_div {
        display: flex;
        justify-content: center;
      }

      .sub_div_two {
        span {
          font-size: 18px;
        }

        form .label_content .schedule_div {
          width: 70%;
          .time_date_btn {
            gap: 10px;
          }
        }
      }
    }
  }

  @media (min-width: 567px) and (max-width: 1024px) {
    padding: 30px 20px;
    .main_div {
      gap: 0;
    }
  }
`;
