import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "aos/dist/aos.css";
import { Route, Routes } from "react-router-dom";
import HirePA from "./Components/CommonPages/HireIndianTalent/HireDigitalMarketing/HirePA/HirePA";
import HireIOS from "./Components/CommonPages/HireIndianTalent/HireDeveloper/HireIOS/HireIOS";
import HireReactNative from "./Components/CommonPages/HireIndianTalent/HireDeveloper/HireReactNative/HireReactNative";
import WhyIndia from "./Components/CommonPages/WhyPhanom/WhyIndia/WhyIndia";
import HowItWorks from "./Components/CommonPages/WhyPhanom/HowItWorks/HowItWorks";
import HireWebDesigner from "./Components/CommonPages/HireIndianTalent/HireDesigner/HireWebDesigner/HireWebDesigner";
import HireUiuxDesigner from "./Components/CommonPages/HireIndianTalent/HireDesigner/HireUiuxDesigner/HireUiuxDesigner";
import HireGraphic from "./Components/CommonPages/HireIndianTalent/HireDesigner/HireGraphic/HireGraphic";
import HireReactJs from "./Components/CommonPages/HireIndianTalent/HireDeveloper/HireReactJs/HireReactJs";
import HireNodejs from "./Components/CommonPages/HireIndianTalent/HireDeveloper/HireNodeJs/HireNodejs";
import HireLaravel from "./Components/CommonPages/HireIndianTalent/HireDeveloper/HireLaravel/HireLaravel";
import HireShopify from "./Components/CommonPages/HireIndianTalent/HireDeveloper/HireShopify/HireShopify";
import HirePHP from "./Components/CommonPages/HireIndianTalent/HireDeveloper/HirePHP/HirePHP";
import HireWordPress from "./Components/CommonPages/HireIndianTalent/HireDeveloper/HireWordPress/HireWordPress";
import HireAndroid from "./Components/CommonPages/HireIndianTalent/HireDeveloper/HireAndroid/HireAndroid";
import HireSoftware from "./Components/CommonPages/HireIndianTalent/HireDeveloper/HireSoftware/HireSoftware";
import HireMobileApp from "./Components/CommonPages/HireIndianTalent/HireDeveloper/HireMobileApp/HireMobileApp";
import VettingProcess from "./Components/CommonPages/WhyPhanom/VettingProcess/VettingProcess";
import HirePpcExpert from "./Components/CommonPages/HireIndianTalent/HireDigitalMarketing/HirePpcExpert/HirePpcExpert";
import HireSeoExpert from "./Components/CommonPages/HireIndianTalent/HireDigitalMarketing/HireSeoExpert/HireSeoExpert";
// import InnerPages from "./Components/Buyer/categoryPages/InnerPages";
import { useDispatch, useSelector } from "react-redux";
// import Dashboard from "./Components/Seller/TopNavPages/Dashboard/Dashboard";
// import Orders from "./Components/Seller/TopNavPages/MyBusiness/orders/Orders";
// import Gigs from "./Components/Seller/TopNavPages/MyBusiness/gigs/Gigs";
// import Profile from "./Components/Seller/TopNavPages/MyBusiness/profile/Profile";
// import Earnings from "./Components/Seller/TopNavPages/MyBusiness/earning/Earnings";
// import Overview from "./Components/Seller/TopNavPages/Analytics/Overview";
// import CreatePage from "./Components/Seller/create/CreatePage";
import PageNF from "./Components/Important/PageNF";
import { loaderAction } from "./redux/users/action";
import { useEffect } from "react";
import Loader from "./Components/Important/Loader";
// import Analytics from "./Components/Seller/TopNavPages/Analytics/Analytics";
import LoginSeller from "./Components/CommonPages/loginPages/LoginSeller";
// import Pricing from "./Components/Seller/create/pricing/Pricing";
// import DescriptionFAQ from "./Components/Seller/create/description/DescriptionFAQ";
// import Gallery from "./Components/Seller/create/gallery/Gallery";
// import Requirements from "./Components/Seller/create/requirements/Requirements";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import EditGigsPage from "./Components/Seller/TopNavPages/MyBusiness/gigs/editGigs/EditGigsPage";
// import Topkeywords from "./Components/Seller/TopNavPages/Analytics/Topkeywords";
import LoginBuyer from "./Components/CommonPages/loginPages/LoginBuyer";
// import Page1 from "./Components/Buyer/profilePlanPages/Page1";
// import Userone from "./Components/Chat/Userone";
// import CreateSingMile from "./Components/Chat/CreateSingMile";
import SellerRegister from "./Components/CommonPages/loginPages/SellerRegister";
// import Portfolio from "./Components/Portfolio/Portfolio";
// import CreatePortfolio from "./Components/Portfolio/CreatePortfolio";
import NoInternetConnection from "./Components/Important/NoInternetConnection";
import BuyerRegister from "./Components/CommonPages/loginPages/BuyerRegister";
// import BrowseHistory from "./Components/Buyer/categoryPages/BrowseHistory";
// import Level from "./Components/Seller/TopNavPages/Dashboard/Level";
// import ShowOrder from "./Components/Seller/TopNavPages/MyBusiness/orders/showOrders/ShowOrder";
// import Preview from "./Components/Seller/TopNavPages/MyBusiness/profile/Preview/Preview";
import { Seo } from "./HomePages/ServicePages/Seo/Seo";
import { YouTubeMarketing } from "./HomePages/ServicePages/YouTubeMarketing/YouTubeMarketing";
import { PayPerClick } from "./HomePages/ServicePages/PayPerClick/PayPerClick";
import { WebDevelopment } from "./HomePages/ServicePages/WebDevelopment/WebDevelopment";
import { Java } from "./HomePages/ServicePages/Java/Java";
import { Shopify } from "./HomePages/ServicePages/Shopify/Shopify";
import Magento from "./Components/Serv/Magento/Magento";
import GraphicDesign from "./Components/Serv/GraphicDesign/GraphicDesign";
import WebsiteDesign from "./Components/Serv/WebsiteDesign/WebsiteDesign";
import RotoScoping from "./Components/Serv/RotoScoping/RotoScoping";
import Animation from "./Components/Serv/Animation/Animation";
import ECommerceMarketing from "./Components/Serv/ECommerceMarketing/ECommerceMarketing";
import AmazonMarketing from "./Components/Serv/AmazonMarketing/AmazonMarketing";
import Wordpress from "./Components/Serv/Wordpress/Wordpress";
import Php from "./Components/Serv/Php/Php";
import VideoMarketing from "./Components/Serv/VideoMarketing/VideoMarketing";
import Branding from "./Components/Serv/Branding/Branding";
import PackagingandLabeling from "./Components/Serv/PackagingandLabeling/PackagingandLabeling";
import { SocialMediaMarketing } from "./HomePages/ServicePages/SocialMediaMarketing/SocialMediaMarketing";
import Ourportfolio from "./Components/CommonPages/Ourportfolio/Ourportfolio";
import ShopifyPublicApp from "./Components/CommonPages/HireIndianTalent/HireDeveloper/ShopifyPublicApp/ShopifyPublicApp";
// import SeoRedirect from "./Components/Serv/SeoRedirect";
import { LandingPage } from "./Components/CommonPages/LandingPage/LandingPage";
import { Upload } from "./Components/CommonPages/Ourportfolio/Upload";
import { Texteditor } from "./Components/CommonPages/Ourportfolio/Texteditor";
import { Editor } from "./Components/CommonPages/Ourportfolio/Editor";
import { Contact } from "./Components/CommonPages/Contact/Contact";
import { HireForm } from "./Components/CommonPages/HireForm/HireForm";
import { PrivacyPolicy } from "./Components/Policies/PrivacyPolicy";
import { RefundPolicy } from "./Components/Policies/RefundPolicy";
import TermCondition from "./Components/Policies/TermCondition";
import Thumbnail from "./Components/CommonPages/Ourportfolio/Thumbnail";
import { AdminBlog } from "./Components/CommonPages/Blogs/AdminBlog";
import { UploadBlog } from "./Components/CommonPages/Blogs/UploadBlog";
import { Content } from "./Components/CommonPages/Blogs/Content";
import { BlogDetails } from "./Components/CommonPages/Blogs/BlogDetails";
import { Blogs } from "./Components/CommonPages/Blogs/Blogs";
import { Appointment } from "./Components/CommonPages/Appointment/Appointment";
import ThankyouPage from "./Components/CommonPages/LandingPage/ThankyouPage";
import { CaseStudy } from "./Components/CommonPages/CaseStudy/CaseStudy";
import { CaseStudyDetails } from "./Components/CommonPages/CaseStudy/CaseStudyDetails";
import { AdminCaseStudy } from "./Components/CommonPages/CaseStudy/AdminCaseStudy";
import { UploadCaseStudy } from "./Components/CommonPages/CaseStudy/UploadCaseStudy";
import { EditBlog } from "./Components/CommonPages/Blogs/EditBlog";
import { HelmetProvider } from "react-helmet-async";
import { Apply } from "./Components/CommonPages/LandingPage/Apply";
import Layout from "./Layout";
import { Home } from "./HomePages/Home";
import { Hosting } from "./HomePages/Hosting/Hosting";

function App() {
  const userCheck = useSelector((state) => state?.users?.userCheck);
  const userDetails = useSelector((state) => state?.users.user);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loaderAction(false));
  });

  return (
    <div>
      <NoInternetConnection>
        <Layout>
          <HelmetProvider>
            <Routes>
              {userCheck && token ? (
                <>
                  {/* <Route path="/user" element={<Userone />} /> cdcdcdcd*/}
                  {/* <Route path="/portfolio" element={<Portfolio />} />
                  <Route path="/level" element={<Level />} />
                  <Route path="/" element={<InnerPages />} />
                  <Route path="/profile/:username" element={<Profile />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/preview" element={<Preview />} />
                  <Route path="/preview/:id" element={<Preview />} /> */}

                  {/* <Route path="/preview/:id" element={<Preview />} /> */}

                  {userDetails.role === "buyer" ? (
                    <>
                      {/* buyer pagess--------------------------------------------------------------- */}
                      {/* <Route path="/editgigspages/:id" element={<Page1 />} />
                      <Route path="/browser/:id" element={<BrowseHistory />} />
                      <Route path="/browser" element={<BrowseHistory />} />
                      <Route path="/innerpages" element={<InnerPages />} /> */}
                    </>
                  ) : userDetails.role === "seller" ? (
                    <>
                      {/* seller pages ---------------------------------------------------------------*/}
                      {/* <Route
                        path="/createportfolio"
                        element={<CreatePortfolio />}
                      />
                      <Route
                        path="/createsingmile"
                        element={<CreateSingMile />}
                      />
                      <Route path="/preview/:id" element={<Preview />} />
                      <Route path="/preview" element={<Preview />} />
                      <Route path="/showorder" element={<ShowOrder />} />
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/editgigspages/:id" element={<Page1 />} />
                      <Route path="/orders" element={<Orders />} />
                      <Route path="/gigs" element={<Gigs />} />
                      <Route path="/pricing" element={<Pricing />} />
                      <Route path="/description" element={<DescriptionFAQ />} />
                      <Route path="/requirements" element={<Requirements />} />
                      <Route path="/gallery" element={<Gallery />} />
                      <Route path="/create" element={<CreatePage />} />
                      <Route
                        path="editgigs/:gigId"
                        element={<EditGigsPage />}
                      />
                      <Route path="editgigs" element={<EditGigsPage />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/earnings" element={<Earnings />} />
                      <Route path="/analytics" element={<Analytics />} />
                      <Route path="/overview" element={<Overview />} />
                      <Route path="/topkeywords" element={<Topkeywords />} /> */}
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  {/* common pages -------------------------------------------------------------- */}

                  <Route path="/" element={<Home />} />
                  {/* <Route path="/editgigspages/:id" element={<Page1 />} /> */}
                  {/* <Route path="/editgigspages" element={<Page1 />} /> */}
                  <Route path="/home" element={<Home />} />
                  <Route path="*" element={<PageNF />} />
                  <Route path="/vettingprocess" element={<VettingProcess />} />
                  <Route
                    path="/hire-mobile-app-developers-in-india/"
                    element={<HireMobileApp />}
                  />
                  <Route
                    path="/hire-dedicated-software-developers-in-india/"
                    element={<HireSoftware />}
                  />
                  <Route
                    path="/hire-dedicated-android-app-developers-in-india/"
                    element={<HireAndroid />}
                  />
                  <Route
                    path="/hire-wordpress-developers-in-india/"
                    element={<HireWordPress />}
                  />
                  <Route
                    path="/hire-expert-php-developers-in-india/"
                    element={<HirePHP />}
                  />
                  <Route
                    path="/hire-shopify-developers-in-india/"
                    element={<HireShopify />}
                  />
                  <Route
                    path="/hire-dedicated-laravel-developers-in-india/"
                    element={<HireLaravel />}
                  />
                  <Route
                    path="/hire-dedicated-nodejs-developers-in-india/"
                    element={<HireNodejs />}
                  />
                  <Route
                    path="/hire-dedicated-reactjs-developers-in-india/"
                    element={<HireReactJs />}
                  />
                  <Route
                    path="/hiregraphic-designers-in-india/"
                    element={<HireGraphic />}
                  />
                  <Route
                    path="/hire-ui-designers-in-india/"
                    element={<HireUiuxDesigner />}
                  />
                  <Route
                    path="/hire-web-designers-in-india/"
                    element={<HireWebDesigner />}
                  />
                  <Route path="/hirepa" element={<HirePA />} />
                  <Route
                    path="/hire-seo-experts-in-india/"
                    element={<HireSeoExpert />}
                  />
                  <Route
                    path="/hire-pay-per-click-experts-in-india/"
                    element={<HirePpcExpert />}
                  />
                  <Route
                    path="/hire-dedicated-ios-app-developer-in-india/"
                    element={<HireIOS />}
                  />
                  <Route
                    path="/hire-dedicated-react-native-developers-in-india/"
                    element={<HireReactNative />}
                  />
                  <Route path="/whyindia" element={<WhyIndia />} />
                  <Route path="/howitworks" element={<HowItWorks />} />
                  <Route path="/loginBuyer" element={<LoginBuyer />} />
                  <Route path="/loginseller" element={<LoginSeller />} />
                  <Route path="/sellerregister" element={<SellerRegister />} />
                  <Route path="/buyerregister" element={<BuyerRegister />} />
                  <Route
                    path="/offshore-seo-company-in-india/"
                    element={<Seo />}
                  />
                  <Route path="/seo" element={<Seo />} />
                  <Route path="/ourportfolio" element={<Ourportfolio />} />
                  <Route path="/thumbnail" element={<Thumbnail />} />
                  <Route path="/upload" element={<Upload />} />
                  <Route path="/upload/new/:id" element={<Texteditor />} />
                  <Route path="/editor" element={<Editor />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/refund-policy" element={<RefundPolicy />} />
                  <Route path="/term-condition" element={<TermCondition />} />
                  {/* <Route
                  path="socialmediamarketing"
                  element={<SocialMediaMarketing />}
                /> */}
                  <Route
                    path="/offshore-youtube-marketing-services-in-india/"
                    element={<YouTubeMarketing />}
                  />

                  <Route
                    path="/youtube-marketing"
                    element={<YouTubeMarketing />}
                  />

                  <Route
                    path="/offshore-ppc-marketing-agency-in-india/"
                    element={<PayPerClick />}
                  />

                  <Route path="/payperclick" element={<PayPerClick />} />

                  <Route
                    path="/offshore-website-development-services-in-india/"
                    element={<WebDevelopment />}
                  />
                  <Route path="/webdevelopment" element={<WebDevelopment />} />

                  <Route
                    path="/offshore-java-software-development-company-in-india/"
                    element={<Java />}
                  />
                  <Route path="/java" element={<Java />} />

                  <Route
                    path="/offshore-shopify-web-development-services-in-india/"
                    element={<Shopify />}
                  />
                  <Route path="/shopify" element={<Shopify />} />

                  <Route
                    path="/offshore-magento-development-company-in-india/"
                    element={<Magento />}
                  />
                  <Route path="/magento" element={<Magento />} />

                  <Route
                    path="/offshore-graphic-design-agency-in-india/"
                    element={<GraphicDesign />}
                  />
                  <Route path="/graphicdesign" element={<GraphicDesign />} />

                  <Route
                    path="/offshore-web-design-agency-in-india/"
                    element={<WebsiteDesign />}
                  />
                  <Route path="/websitedesign" element={<WebsiteDesign />} />

                  <Route
                    path="/offshore-rotoscoping-services-in-india/"
                    element={<RotoScoping />}
                  />
                  <Route path="/rotoScoping" element={<RotoScoping />} />

                  <Route
                    path="/offshore-animation-company-in-india/"
                    element={<Animation />}
                  />
                  <Route path="/animation" element={<Animation />} />

                  <Route
                    path="/offshore-ecommerce-agency-india/"
                    element={<ECommerceMarketing />}
                  />
                  <Route
                    path="/ecommercemarketing"
                    element={<ECommerceMarketing />}
                  />

                  <Route
                    path="/offshore-amazon-marketing-agency-in-india/"
                    element={<AmazonMarketing />}
                  />
                  <Route
                    path="/amazonmarketing"
                    element={<AmazonMarketing />}
                  />

                  <Route
                    path="/offshore-wordpress-development-company-on-india/"
                    element={<Wordpress />}
                  />
                  <Route path="/wordpress" element={<Wordpress />} />
                  <Route
                    path="/offshore-php-development-company-in-india/"
                    element={<Php />}
                  />
                  <Route path="/php" element={<Php />} />
                  <Route
                    path="/offshore-video-marketing-services-in-India/"
                    element={<VideoMarketing />}
                  />
                  <Route path="/videomarketing" element={<VideoMarketing />} />
                  <Route
                    path="/offshore-branding-agency-in-india/"
                    element={<Branding />}
                  />
                  <Route path="/branding" element={<Branding />} />
                  <Route
                    path="/offshore-packeging-and-labeling-agency-in-india/"
                    element={<PackagingandLabeling />}
                  />
                  <Route
                    path="/packaging-and-labeling"
                    element={<PackagingandLabeling />}
                  />
                  <Route
                    path="/offshore-social-media-marketing-agency-in-india/"
                    element={<SocialMediaMarketing />}
                  />
                  <Route
                    path="/social-media-marketing"
                    element={<SocialMediaMarketing />}
                  />
                  <Route
                    path="/hire-expert-shopify-apps-developers-in-india/"
                    element={<ShopifyPublicApp />}
                  />
                  <Route
                    path="/hire-indian-talent/"
                    element={<LandingPage />}
                  />
                  <Route path="/thank-you" element={<ThankyouPage />} />
                  <Route path="/contact-us" element={<Contact />} />
                  <Route path="/hireform" element={<HireForm />} />
                  <Route path="/blogs" element={<Blogs />} />
                  <Route path="/admin-blog" element={<AdminBlog />} />
                  <Route path="/upload-blog" element={<UploadBlog />} />

                  <Route path="/content" element={<Content />} />
                  {/* <Route path="/blog-details/:id/:slug" element={<BlogDetails/>}/> */}
                  <Route
                    path="/blog-details/:id/:slug"
                    element={<BlogDetails />}
                  />
                  <Route path="/edit-blog/:id" element={<EditBlog />} />
                  <Route path="/appointment" element={<Appointment />} />
                  <Route path="/case-study" element={<CaseStudy />} />
                  <Route
                    path="/case-study-details/:id/:slug"
                    element={<CaseStudyDetails />}
                  />
                  <Route
                    path="/admin-case-study"
                    element={<AdminCaseStudy />}
                  />
                  <Route
                    path="/upload-case-study"
                    element={<UploadCaseStudy />}
                  />

                  <Route path="/apply" element={<Apply />} />
                  <Route path="/hosting" element={<Hosting />} />
                </>
              )}
            </Routes>
          </HelmetProvider>

          <Loader />
          <ToastContainer />
        </Layout>
      </NoInternetConnection>
    </div>
  );
}

export default App;
